const MEDIA_QUERY = {
  PHONE: '@media (max-width: 600px)',
  TABLET: '@media (max-width: 768px)',
  SMALL_LAPTOPS: '@media (max-width: 992px)',
};

export {
  // eslint-disable-next-line import/prefer-default-export
  MEDIA_QUERY,
};
